import React from 'react'

const HeroSectionA2 = () => {
  return (
    <div className='w-full h-auto'>
      <div className='max-w-[1280px] m-auto'>
      <div className='w-full h-auto lg:pt-6 md:pt-10 pt-6 mx-auto px-10 md:px-8 lg:px-0 '>
         <div className='lg:max-w-[926px] md:max-w-[768px] max-w-[328px] mx-auto lg:mt-10 mt-10 text-wrap text-center sm:px-0 md:px-10 lg:px-0'>
            <p className='w-full md:text-[22px] text-[16px] leading-8'>Torqoise is your <span className='decoration-[gray] underline'>AI-powered solution</span> to elevate your hotel’s digital presence.</p>
            <p className='w-full md:text-[22px] text-[16px] leading-8'>With Torqoise, seamlessly integrate, optimize revenue, and enhance guest experiences with just a few clicks.</p>
         </div>


        <div className='w-full h-auto flex flex-col justify-center items-center gap-2 py-10 md:px-12'>
          <a className='text-white font-bold md:text-[24px] text-[18px] bg-[#00B4D8] duration-400 rounded-[12px] text-center grid items-center lg:min-w-[400px] lg:min-h-[60px] md:min-w-[400px] md:min-h-[60px] min-w-[163px] min-h-[51px] hover:bg-[#7ee0f4]' href="/client-info">Book Now</a>
          <p className='w-full text-center text-[14px] text-[gray]'>and get your first year free</p>
        </div>
    </div>
      </div>
    </div>
  )
}

export default HeroSectionA2