import React, { useEffect } from 'react';

const ChatBot = () => {
  useEffect(() => {
    // This function is called when the component mounts
    const script = document.createElement('script');
    script.src = "https://cdn.voiceflow.com/widget-next/bundle.mjs";
    script.type = "text/javascript";

    // When the script is loaded, initialize the Voiceflow chat
    script.onload = () => {
      window.voiceflow.chat.load({
        verify: { projectID: '6796861f92c4506f0617328a' },
        url: 'https://general-runtime.voiceflow.com',
        versionID: 'production',
      });
    };

    // Insert the script tag into the document
    document.body.appendChild(script);

    // Cleanup the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this only runs on mount/unmount

  return null; // The component doesn’t need to render anything itself
};

export default ChatBot;
