import React from 'react'
import Logo from '../Static/Image/Logo.png'

const Header = () => {
  return (
    <div className='w-full h-auto'>
      <div className="w-full h-[70px] border-b-2 border-b-stone-300 fixed z-[1000] opacity-[1] bg-[white]">
            <div className='h-full flex justify-between mx-10 items-center flex-cols-2'>
              <div className='col-span-1'>
                <a href='/' className='m-0 p-0'><img src={Logo} alt='Logo' className='max-w-[94.7px] max-h-[24px] max-md:w-[146px] max-mg:h-[37px] max-lg:w-[146px] max-lg:h-[37px]'/></a>
              </div>
              <div className='col-span-1'>
                <a className='rounded-[12px] px-[12px] py-[8px] bg-[#00B4D8] text-white text-[14px] md:text-[16px]' href="https://app.torqoise.net/">Get Started</a>
              </div>
            </div>
      </div>
</div>
  )
}

export default Header;