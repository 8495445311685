import React from 'react';
import Header from "./Header";
import HeroSectionA from './HeroSectionA';
import HeroSectionA2 from './HeroSectionA2';
import HeroSectionB from './HeroSectionB';
import HeroSectionC from './HeroSectionC';
import HeroSectionD from './HeroSectionD';
import HeroSectionE from './HeroSectionE';
import HeroSectionF from './HeroSectionF';
import HeroSectionG from './HeroSectionG';
import Footer from './Footer';
import '../Components/custom.css'
import ChatBot from './ChatBot'; 




const Layout = () => {
  

  return (
    <div className='w-full h-auto m-auto'>
        <Header/>
        <HeroSectionA/>
        <HeroSectionA2/>
        <HeroSectionB/>
        <HeroSectionC/>
        <HeroSectionD/>
        <HeroSectionF/>
        <HeroSectionE/>
        <HeroSectionG/>
        <Footer/>
        <ChatBot />
    </div>
  )
}

export default Layout